import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import GoogleSignInLanding from "../../../components/googleSignInLanding";
import LandingPageLogoBold from "../../../components/assets/images/LandingPageLogoBold.png";

const Navbar = () => {
  return (
    <nav className="bg-neutral-800 p-4">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center">
        <Link
          to="/"
          className="text-stone-200 text-2xl lg:text-5xl font-md flex items-center mb-4 lg:mb-0"
        >
          <img src={LandingPageLogoBold} alt="FilterOut" className="h-8 lg:h-16 w-8 lg:w-16 mr-2" />
          FilterOut
        </Link>
        <div className="flex flex-row md:text-2xl lg:flex-row items-center w-full lg:w-auto lg:space-x-20 justify-between lg:space-y-0">
          <HashLink
            smooth
            to="/#demo"
            className="text-stone-300 hover:text-neutral-600 font-md"
          >
            Demo
          </HashLink>
          <HashLink
            smooth
            to="/#pricing"
            className="text-stone-300 hover:text-neutral-600 font-md"
          >
            Pricing
          </HashLink>
          <HashLink
            smooth
            to="/#contact"
            className="text-stone-300 hover:text-neutral-600 font-md"
          >
            Contact us
          </HashLink>
          <a
            href="https://chromewebstore.google.com/detail/filterout/bclbpfjnmmjapiffnmkkolmjncokklkj?hl=en-US&utm_source=ext_sidebar"
            target="_blank"
            rel="noopener noreferrer"
            className="text-stone-300 hover:text-neutral-600 font-md"
          >
            Extension
          </a>
          <GoogleSignInLanding />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
