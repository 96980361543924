import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./navbar/Navbar";
import ContactUs from "./contactus/ContactUs";
import LandingPageSlot from "../../components/assets/images/LandingPageSlotHarki.png";

const LandingPage = () => {
  return (
    <>
      <div className="bg-neutral-800 text-white min-h-screen pl-6 overflow-x-hidden">
        <Navbar />

        <main className="container mx-auto px-4 py-2">
          <section className="flex flex-col lg:flex-row items-center justify-between mb-2">
            <div className="lg:w-1/2 text-left pr-8 mb-8 lg:mb-0">
              <h1 className="text-4xl lg:text-7xl font-semibold text-stone-300 mb-10">
                Consume content like you want.
              </h1>
              <p className="text-xl lg:text-3xl text-neutral-600">
                Customize your YouTube feed,
              </p>
              <p className="text-xl lg:text-3xl text-neutral-600 mb-20">
                build focus with mindful content consumption.
              </p>
              <Link
                to="/pricing"
                className="inline-block px-8 py-3 bg-amber-400 text-neutral-800 text-lg rounded-3xl font-extrabold hover:bg-yellow-600"
              >
                Get FilterOut!
              </Link>
            </div>
            <div className="lg:w-1/2 w-full">
              <img
                src={LandingPageSlot}
                alt="YouTube"
                className="w-full h-auto"
              />
            </div>
          </section>
          <section id="demo" className="mb-12 py-4">
            <h2 className="text-xl font-semibold text-amber-400 mt-14 text-center">
              Demo
            </h2>
            <h3 className="text-3xl font-semibold text-stone-300 mt-7 mb-4 text-center">
              Know how the extension works
            </h3>
            <div className="flex item-center justify-center">
              <div className="ratio ratio-16x9 rounded-lg lg:w-[1080px] w-full h-[240px] lg:h-[540px] mx-auto">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/Y9Vu43EHcgY"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </section>

          <section id="pricing" className="mb-12 py-6">
            <h2 className="text-xl font-semibold text-amber-400 mt-14 mb-10 text-center">
              Pricing
            </h2>
            <h3 className="text-2xl font-semibold text-neutral-600 text-center">
              Increase your productivity with our seamless Chrome extension.
            </h3>
            <div className="bg-neutral-700 rounded-2xl p-6 w-fit mx-auto mt-16 h-auto">
              <h3 className="text-l font-bold mb-2 text-center">Its Free!</h3>
              <div className="w-full text-center">
              <span className="text-3xl font-bold mx-auto">₹0</span>
              <span className=" text-xl mb-4 ml-2 mx-auto">INR</span>
              </div>
              <ul className="mb-9 mt-10 ">
                <li className="flex items-start text-semibold lg:text-xl mb-3">
                  ✅ Create customized slots for your channel.
                </li>
                <li className="flex items-start text-semibold lg:text-xl mb-3">
                  ✅ Add up to 5 channels in 1 slot.
                </li>
                <li className="flex items-start text-semibold lg:text-xl mb-3">
                  ✅ Lifetime access.
                </li>
                <li className="flex items-start text-semibold lg:text-xl mb-3">
                  ✅ Applies to one YouTube account only.
                </li>
              </ul>
              <Link
                to="/pricing"
                className="block w-full text-center px-6 py-3 bg-amber-400 text-neutral-800 text-lg rounded-xl font-extrabold hover:bg-yellow-600"
              >
                Get FilterOut!
              </Link>
            </div>
            <h4 className="text-3xl font-semibold text-stone-300 mt-20 text-center">
              From Mindless Scrolling to Mindful Content Consumption
            </h4>
          </section>
        </main>
        <section id="contact" className="mb-12">
          <ContactUs />
        </section>
      </div>
      <footer className="bg-neutral-900 py-6">
        <div className="container mx-auto text-amber-400 text-center">
          <p>&copy; 2024 FilterOut. All rights reserved.</p>
          <p className="mt-2 text-sm">
            <Link to="/privacy-policy" className="text-gray-600 hover:underline mr-2">Privacy Policy</Link>
            {/* <Link to="/privacy-policy-google-auth" className="text-gray-600  hover:underline">Privacy Policy</Link> */}

          </p>
        </div>
      </footer>
    </>
  );
};

export default LandingPage;
