// components/AuthCheck.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const AuthCheck = ({ children }) => {
  const [isChecking, setIsChecking] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        setIsChecking(false);
        return;
      }

      try {
        await axios.get(`/api/protected`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        // If the request is successful, the token is valid
        if (location.pathname === '/' || location.pathname === '/pricing') {
          navigate('/create-slot');
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Token expired, try to refresh
          try {
            const refresh_token = localStorage.getItem('refreshToken');
            const response = await axios.post(`/api/auth/refresh-token`, { refresh_token });
            localStorage.setItem('accessToken', response.data.access_token);
            if (location.pathname === '/' || location.pathname === '/pricing') {
              navigate('/create-slot');
            }
          } catch (refreshError) {
            // If refresh fails, clear tokens
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
          }
        } else {
          // If there's any other error, clear tokens
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
        }
      }
      setIsChecking(false);
    };

    checkAuth();
  }, [navigate, location]);

  if (isChecking) {
    return <div>Loading...</div>;
  }

  return children;
};

export default AuthCheck;
