import React from "react";
import { Link,  } from "react-router-dom";
import useUserInfo from "../hooks/useUserInfo";
import LogoutButton from "./LogoutButton";
import LogoVeryLarge from "./assets/images/LogoVeryLarge.png"
import UserDummy from "./assets/images/user-dummy.png"

const Sidebar = () => {
  const userInfo = useUserInfo();

  return (
    <div className="bg-neutral-800 text-white w-64 min-h-screen p-4 relative">
      <div className="mb-8">
        {userInfo ? (
          <>
            {userInfo.profilePicture ? (
              <img
                src={userInfo.profilePicture}
                alt={userInfo.googleName}
                className="w-24 h-24 rounded-full mx-auto m-4 object-cover"
              />
            ) : (
              <div className="w-24 h-24 rounded-full bg-gray-600 mx-auto mb-4"></div>
            )}
            <h2 className="text-center">{userInfo.googleName}</h2>
          </>
        ) : (
          <>
            <div className="w-24 h-24 rounded-full bg-gray-600 mx-auto mb-4"></div>
            <h2 className="text-center">Loading...</h2>
          </>
        )}
      </div>
      <nav>
        <ul>
          <li className="mb-4">
          <Link
              to="/create-slot"
              className="block py-2 px-4 hover:bg-neutral-600 active:bg-neutral-300 focus:outline-none focus:ring focus:ring-neutral-100 focus:bg-neutral-600 rounded"
            >
              Create Slot
            </Link>
          </li>
          <li className="mb-4">
            <Link
              to="/my-slot"
              className="block py-2 px-4 hover:bg-neutral-600 active:bg-neutral-300 focus:outline-none focus:ring focus:ring-neutral-100 focus:bg-neutral-600 rounded"
            >
              My Slots
            </Link>
          </li>
          <li className="mb-4">
            <Link
              to="/contactus"
              className="block py-2 px-4 hover:bg-neutral-600 active:bg-neutral-300 focus:outline-none focus:ring focus:ring-neutral-100 focus:bg-neutral-600 rounded"
            >
              Contact us
            </Link>
          </li>
          <li className="mb-4">
          <a
            href="https://chromewebstore.google.com/detail/filterout/bclbpfjnmmjapiffnmkkolmjncokklkj?hl=en-US&utm_source=ext_sidebar"
            target="_blank"
            rel="noopener noreferrer"
            className="block py-2 px-4 hover:bg-neutral-600 active:bg-neutral-300 focus:outline-none focus:ring focus:ring-neutral-100 focus:bg-neutral-600 rounded"
            >
            Extension
          </a>
          </li>
          <li>
            <LogoutButton />
          </li>
        </ul>
       
      </nav>
      <div className="absolute bottom-0 overflow-hidden left-0 right-10 p-4 flex justify-center items-center">
        <img 
          src={LogoVeryLarge} 
          alt="Logo" 
          className="w-8 opacity-10"
        />
        <div className="text-2xl opacity-10">
          FilterOut
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
