import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const GoogleSignInLanding = () => {
  const navigate = useNavigate();

  const handleSuccess = async (response) => {
    console.log("Login Success. Full response:", response);
    try {
      const userInfoResponse = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        },
      );

      const userInfo = {
        email: userInfoResponse.data.email,
        name: userInfoResponse.data.name,
      };
      // Store Google access token separately in local storage
      localStorage.setItem("google_accessToken", response.access_token);
      localStorage.setItem("google_refreshToken", response.refresh_token);
      const result = await axios.post(
        `/api/auth/google-signin`,
        userInfo,
      );
      if (result.data.access_token) {
        localStorage.setItem("accessToken", result.data.access_token);
        localStorage.setItem("refreshToken", result.data.refresh_token);
      }
      navigate(result.data.redirect);
    } catch (error) {
      console.log("auth failed in signin", error);
    }
  };

  const handleError = (error) => {
    console.error("Login Failed:", error);
  };

  const login = useGoogleLogin({
    onSuccess: handleSuccess,
    onError: handleError,
    scope: "email profile https://www.googleapis.com/auth/youtube.readonly",
    access_type: "offline", // Ensure refresh_token is included
  });
  
  return (
    <button
      className="text-stone-300 text-base lg:text-2xl hover:text-neutral-600 font-md"
      onClick={() => login()}
    >
      Sign in
    </button>
  );
};

export default GoogleSignInLanding;
