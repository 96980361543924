import React from 'react';
import { Box, Heading, Text, Container } from '@chakra-ui/react';

const ExtensionPolicy = () => {
  return (
    <Container maxW="container.xl" py={10}>
      <Box>
        <Heading as="h1" size="xl" mb={6}>
          FilterOut Chrome Extension Privacy Policy
        </Heading>
        <Text mb={4}>Last Updated: August-11-2024</Text>
        
        <Heading as="h2" size="lg" mt={6} mb={4}>
          1. Introduction
        </Heading>
        <Text mb={4}>
          This Privacy Policy explains how FilterOut collects, uses, and protects your information when you use our Chrome extension.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={4}>
          2. Information We Collect
        </Heading>
        <Text mb={4}>
          Our extension collects the following types of information:
        </Text>
        <ul>
          <li>Google account information: email for payment verification in our backend (through OAuth2)</li>
        </ul>

        <Heading as="h2" size="lg" mt={6} mb={4}>
          3. Contact Us
        </Heading>
        <Text mb={4}>
          If you have any questions about this Privacy Policy, please contact us at: filterout.in@gmail.com
        </Text>
      </Box>
    </Container>
  );
};

export default ExtensionPolicy;