import React from 'react';
import { Box, Heading, Text, Container, UnorderedList, ListItem } from '@chakra-ui/react';

const GoogleOAuthPolicy = () => {
  return (
    <Container maxW="4xl" py={8}>
      <Box borderWidth={1} borderRadius="lg" p={6}>
        <Heading as="h1" size="xl" mb={4}>Privacy Policy</Heading>
        
        <Text mb={4}>
          This Privacy Policy outlines how our application collects, uses, and protects your personal information when you use our service.
        </Text>
        
        <Heading as="h2" size="lg" mt={6} mb={2}>Information We Collect</Heading>
        <Text mb={2}>
          When you authenticate with Google, we request access to the following information:
        </Text>
        <UnorderedList mb={4}>
          <ListItem>Your email address</ListItem>
          <ListItem>Your name</ListItem>
          <ListItem>Your YouTube subscription data (view-only access)</ListItem>
        </UnorderedList>
        
        <Heading as="h2" size="lg" mt={6} mb={2}>How We Use Your Information</Heading>
        <Text mb={2}>
          We use the collected information solely for the purpose of providing you with our service, which includes:
        </Text>
        <UnorderedList mb={4}>
          <ListItem>Personalizing your experience within our application</ListItem>
          <ListItem>Displaying your YouTube subscriptions</ListItem>
          <ListItem>Communicating with you about our service</ListItem>
        </UnorderedList>
        
        <Heading as="h2" size="lg" mt={6} mb={2}>Data Protection</Heading>
        <Text mb={4}>
          We are committed to protecting your personal information. We implement industry-standard security measures to prevent unauthorized access, disclosure, or alteration of your data.
        </Text>
        
        <Heading as="h2" size="lg" mt={6} mb={2}>Third-Party Access</Heading>
        <Text mb={4}>
          We do not share your personal information with any third parties, except as required by law or with your explicit consent.
        </Text>
        
        <Heading as="h2" size="lg" mt={6} mb={2}>Your Rights</Heading>
        <Text mb={2}>
          You have the right to:
        </Text>
        <UnorderedList mb={4}>
          <ListItem>Access the personal information we hold about you</ListItem>
          <ListItem>Request correction of any inaccurate information</ListItem>
          <ListItem>Request deletion of your data from our systems</ListItem>
          <ListItem>Withdraw your consent for data processing at any time</ListItem>
        </UnorderedList>
        
        
        <Heading as="h2" size="lg" mt={6} mb={2}>Contact Us</Heading>
        <Text mb={4}>
          If you have any questions about this Privacy Policy, please contact us at: filterout.in@gmail.com
        </Text>
      </Box>
    </Container>
  );
};

export default GoogleOAuthPolicy;