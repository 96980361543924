import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import SearchBar from "../../components/searchBar";
import api from "../../utils/api";
import {
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

const MySlots = () => {
  const [slots, setSlots] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [editingSlot, setEditingSlot] = useState(null);
  const [oldSlotName, setOldSlotName] = useState("");
  const [slotToDelete, setSlotToDelete] = useState(null);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    fetchSlots();
  }, []);

  const fetchSlots = async () => {
    try {
      const response = await api.get(`/my-slot`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setSlots(response.data.slots);
    } catch (error) {
      console.error("Error fetching slots:", error);
      toast({
        title: "Error",
        description: "Failed to fetch slots. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleDeleteSlot = (slotName) => {
    setSlotToDelete(slotName);
    onOpen();
  };

  const confirmDelete = async () => {
    try {
      await api.delete(`/my-slot`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        data: { slotName: slotToDelete },
      });
      onClose();
      fetchSlots();
      toast({
        title: "Slot deleted",
        description: `The slot "${slotToDelete}" has been successfully deleted.`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.error("Error deleting slot:", error);
      toast({
        title: "Error",
        description: "Failed to delete the slot. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
    setSlotToDelete(null);
  };

  const handleSubmitSlotName = async (oldSlotName, newSlotName) => {
    try {
      await api.patch(
        `/my-slot`,
        { oldSlotName, newSlotName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      setEditingSlot(null);
      fetchSlots();
      toast({
        title: "Slot updated",
        description: `Slot name successfully changed from "${oldSlotName}" to "${newSlotName}".`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.error("Error updating slot name:", error);
      toast({
        title: "Error",
        description: "Failed to update slot name. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleKeyDown = (e, oldSlotName, newSlotName) => {
    if (e.key === "Enter") {
      handleSubmitSlotName(oldSlotName, newSlotName);
    }
  };

  const filteredSlots = slots.filter((slot) =>
    slot.slotName.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div className="container mx-auto px-4 bg-white py-8">
      <h1 className="text-3xl text-neutral-800 flex justify-center items-center font-bold mb-6">
        My Slots
      </h1>
      <div className="mb-6 flex justify-center">
        <div className="w-3/4 md:w-1/2 lg:w-1/3">
          <SearchBar
            searchTerm={searchTerm}
            onSearchChange={setSearchTerm}
            placeholder_input="search slots..."
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 m-6 mx-12">
        {filteredSlots.map((slot) => (
          <div
            key={slot.id}
            className="bg-zinc-100 hover:bg-neutral-200 rounded-lg shadow-lg p-3 py-3 relative group"
          >
            {editingSlot === slot.id ? (
              <input
                type="text"
                value={slot.slotName}
                onChange={(e) => {
                  const updatedSlots = slots.map((s) =>
                    s.id === slot.id ? { ...s, slotName: e.target.value } : s,
                  );
                  setSlots(updatedSlots);
                }}
                onBlur={() => handleSubmitSlotName(oldSlotName, slot.slotName)}
                onKeyDown={(e) => handleKeyDown(e, oldSlotName, slot.slotName)}
                className="w-full mb-2 p-2 border rounded"
              />
            ) : (
              <h2 className="text-xl font-semibold mb-4 flex justify-between items-center">
                {slot.slotName}
                <div>
                  <button
                    onClick={() => {
                      setEditingSlot(slot.id);
                      setOldSlotName(slot.slotName);
                    }}
                    className="text-blue-500 mr-2"
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => handleDeleteSlot(slot.slotName)}
                    className="text-red-500"
                  >
                    <FaTrash />
                  </button>
                </div>
              </h2>
            )}
            <div className="flex flex-wrap justify-center gap-6">
              {slot.slotData.map((channel) => (
                <div key={channel.channelId} className="relative group">
                  <img
                    src={channel.thumbnails.default.url}
                    alt={channel.title}
                    className="w-20 h-20 rounded-full object-cover shadow-lg transform hover:scale-110 transition-transform"
                  />
                  <div className="absolute inset-0 flex items-center text-center justify-center opacity-0 hover:opacity-100 bg-black bg-opacity-75 text-white text-sm rounded-full transition-opacity">
                    {channel.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete the slot "{slotToDelete}"?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={confirmDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default MySlots;