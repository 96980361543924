import React, { useState } from "react";
import { FaLinkedin, FaTwitter, FaInstagram, FaYoutube, FaEnvelope } from "react-icons/fa";
import AyushGuptaImg from "../../components/assets/images/AyushGuptaImg.png";
import LandingPageLogo from "../../components/assets/images/LandingPageLogo.png"
import filter128 from "../../components/assets/images/filter128.png"
import TiyaJainImg from "../../components/assets/images/TiyaJainImg.png";
import QuoteImg from "../../components/assets/images/QuoteImg.png";
import api from "../../utils/api";

const ContactUs = () => {
  const [selectedOption, setSelectedOption] = useState("submit feedback");
  const [formData, setFormData] = useState({
    name: "",
    message: "",
  });
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");


  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage("");

    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("message", formData.message);
    formDataToSend.append("type", selectedOption);
    if (file) {
      formDataToSend.append("file", file);
    }

    try {
      const response = await api.post("/send-mail/main-feedback", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSubmitMessage("Your message has been sent successfully!");
      setFormData({ name: "", message: "" });
      setFile(null);
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmitMessage("There was an error sending your message. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

   const developers = [
    {
      name: "FilterOut",
      img: filter128,
      links: {
        linkedin: "https://www.linkedin.com/company/filterout/",
        // youtube: "https://www.youtube.com/@madmecodes",
        // twitter: "https://x.com/madmecodes",
        email: "mailto:filterout.in@gmail.com",
      }
    },
    // {
    //   name: "Ayush Gupta",
    //   img: AyushGuptaImg,
    //   links: {
    //     linkedin: "https://www.linkedin.com/in/ayush-gupta-dev/",
    //     youtube: "https://www.youtube.com/@madmecodes",
    //     twitter: "https://x.com/madmecodes",
    //     email: "mailto:ayushguptadev1@gmail.com",
    //   }
    // },
    // {
    //   name: "Tiya Jain",
    //   img: TiyaJainImg,
    //   links: {
    //     linkedin: "https://www.linkedin.com/in/tiyajain24/",
    //     twitter: "https://x.com/TiyaJain24",
    //     email: "mailto:tiyajain4124@gmail.com",
    //   }
    // }
  ];

  return (
   
    <div className="flex min-h-screen p-8">
      <div className="w-2/3 pr-12">
        <h1 className="text-4xl font-bold mb-8">Contact Us</h1>
        <div className="flex space-x-6 mb-8">
          {["Submit feedback", "Report an error", "Request a feature"].map(
            (option) => (
              <button
                key={option}
                className={`py-3 px-6 rounded-full text-lg ${ 
                  selectedOption === option.toLowerCase()
                    ? "bg-amber-500 text-white"
                    : "bg-gray-200 text-gray-600"
                }`}
                onClick={() => handleOptionChange(option.toLowerCase())}
              >
                {option}
              </button>
            ),
          )}
        </div>
        <form onSubmit={handleSubmit}>
          <input
            className="w-full mb-6 p-3 border rounded text-lg"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Name"
            required
          />
          <textarea
            className="w-full resize-none mb-6 p-3 border rounded text-lg"
            rows="6"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            placeholder={`Your ${selectedOption}`}
            required
          ></textarea>
          <div className="mb-6">
            <label className="block text-lg font-medium mb-2">
              Attach Files (Optional)
            </label>
            <input
              className="w-full text-lg text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-lg file:font-semibold file:bg-yellow-50 file:text-yellow-700 hover:file:bg-yellow-100"
              type="file"
              onChange={handleFileChange}
            />
          </div>
          <button
            className="w-full bg-amber-500 text-white font-bold py-3 px-6 rounded text-lg"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
          {submitMessage && (
            <p className={`mt-4 text-center ${submitMessage.includes("error") ? "text-red-500" : "text-green-500"}`}>
              {submitMessage}
            </p>
          )}
        </form>
      </div>
      <div className="w-1/3 pl-12 border-l my-10">
        <h2 className="text-2xl font-semibold my-8">Contact</h2>
        {developers.map((dev) => (
        <div key={dev.name} className="mb-8 flex items-center">
          <img
            src={dev.img}
            alt={dev.name}
            className="w-20 h-20 mr-6" //removed rounded-full
          />
          <div>
            <h3 className="font-bold text-lg">{dev.name}</h3>
            <div className="flex space-x-3 mt-2">
              {dev.links.linkedin && (
                <a href={dev.links.linkedin} target="_blank" rel="noopener noreferrer">
                  <FaLinkedin className="text-gray-400 hover:text-gray-800" size={24} />
                </a>
              )}
              {dev.links.youtube && (
                <a href={dev.links.youtube} target="_blank" rel="noopener noreferrer">
                  <FaYoutube className="text-gray-400 hover:text-gray-800" size={24} />
                </a>
              )}
              {dev.links.twitter && (
                <a href={dev.links.twitter} target="_blank" rel="noopener noreferrer">
                  <FaTwitter className="text-gray-400 hover:text-gray-800" size={24} />
                </a>
              )}
              {dev.links.email && (
                <a href={dev.links.email}>
                  <FaEnvelope className="text-gray-400 hover:text-gray-800" size={24} />
                </a>
              )}
            </div>
          </div>
        </div>
      ))}
        <div className="mt-12">
          <div className="relative">
            {/* <img
              src={QuoteImg}
              alt="Quote"
              className="w-12 h-12 absolute -top-6 -left-6"
            /> */}
            {/* <div className="bg-gray-100 p-6 rounded-lg">
              <h3 className="text-2xl font-semibold mb-4">Hey!</h3>
              <p className="text-gray-700">
              Optimizing YouTube feeds by selecting the slots we want and focusing on learning sounded really promising to us.
We hope we have helped improve your YouTube feed and that you're enjoying it. If you have any questions, please let us know. The feedback form is always open. This is our genuine effort to turn this idea into reality.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
