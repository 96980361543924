import axios from "axios";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import SearchBar from "../../components/searchBar";
import api from "../../utils/api";
import { useToast } from "@chakra-ui/react";


const CreateSlot = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [displayedSubscriptions, setDisplayedSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [slotName, setSlotName] = useState("");
  const [selectedChannels, setSelectedChannels] = useState([]);
  const subscriptionsRef = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const toast = useToast();


  const ITEMS_PER_PAGE = 20;

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (
      source.droppableId === "subscriptions" &&
      destination.droppableId === "selectedChannels"
    ) {
      if (selectedChannels.length >= 5) {
        toast({
          title: "Maximum channels reached",
          description: "You can't add more than 5 channels to a slot.",
          status: "warning",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        return;
      }

      const sourceItems = Array.from(displayedSubscriptions);
      const destItems = Array.from(selectedChannels);
      const [movedItem] = sourceItems.splice(source.index, 1);

      if (
        destItems.find((channel) => channel.channelId === movedItem.channelId)
      ) {
        toast({
          title: "Channel already selected",
          description: "This channel is already in the selected slot.",
          status: "info",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        return;
      }


      destItems.splice(destination.index, 0, movedItem);
      setDisplayedSubscriptions(sourceItems);
      setSelectedChannels(destItems);
    }
  };

  const removeFromSlot = (channelId) => {
    setSelectedChannels((prevChannels) =>
      prevChannels.filter((channel) => channel.channelId !== channelId),
    );
    setDisplayedSubscriptions((prevSubscriptions) => [
      ...prevSubscriptions,
      selectedChannels.find((channel) => channel.channelId === channelId),
    ]);
  };

  const createSlot = async () => {
    if (selectedChannels.length === 0) {
      toast({
        title: "No channels selected",
        description: "Please select at least one channel for your slot.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    if (slotName.trim() === "") {
      toast({
        title: "Slot name required",
        description: "Please enter a name for your slot.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    console.log("Creating slot:", {
      name: slotName,
      channels: selectedChannels.map((channel) => channel.channelId),
    });
    try {
      await api.post(
        `/create-slot/`,
        { slotName, selectedChannels },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      setSlotName("");
      setSelectedChannels([]);
      toast({
        title: "Slot created",
        description: "Your slot has been created successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.log("error creating slot in backend", error);
      toast({
        title: "Error",
        description: "Failed to create slot. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const fetchSubscriptions = async () => {
    let googleAccessToken = localStorage.getItem("google_accessToken");
    try {
      const response = await api.get(`/subscriptions`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "x-google-access-token": googleAccessToken,
          "x-google-refresh-token": localStorage.getItem("google_refreshToken"),
        },
      });

      // Check if a new access token was sent
      const newAccessToken = response.headers["x-google-access-token"];
      if (newAccessToken) {
        localStorage.setItem("google_accessToken", newAccessToken);
        googleAccessToken = newAccessToken; // Update the local variable
      }

      setSubscriptions(response.data.channels);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching subscriptions", error);
      setError("Failed to fetch subscriptions");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  useEffect(() => {
    const filtered = subscriptions.filter(
      (channel) =>
        channel.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !selectedChannels.some(
          (selectedChannel) => selectedChannel.channelId === channel.channelId,
        ),
    );
    const newDisplayedSubscriptions = filtered.slice(0, page * ITEMS_PER_PAGE);
    setDisplayedSubscriptions(filtered.slice(0, page * ITEMS_PER_PAGE));
    setHasMore(newDisplayedSubscriptions.length < filtered.length);
  }, [subscriptions, searchTerm, page, selectedChannels]);

  const loadMore = useCallback(() => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [hasMore]);

  const handleScroll = useCallback(() => {
    if (subscriptionsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        subscriptionsRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        loadMore();
      }
    }
  }, [loadMore, hasMore]);

  useEffect(() => {
    const currentRef = subscriptionsRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
      handleScroll();
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex">
        <div className="flex-1 md:p-8 p-2">
          <h1 className="text-3xl text-neutral-800 font-bold mb-8">
            YOUR SUBSCRIPTIONS
          </h1>
          <div className="flex">
            <div className="md:w-2/3 w-full md:pr-8 pr-2">
              <SearchBar
                searchTerm={searchTerm}
                placeholder_input="search channels..."
                onSearchChange={setSearchTerm}
              />
              <Droppable droppableId="subscriptions">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={(el) => {
                      provided.innerRef(el);
                      subscriptionsRef.current = el;
                    }}
                    className="grid md:grid-cols-2 flex-col md:gap-4 overflow-y-auto"
                    style={{ height: "600px" }}
                  >
                    {displayedSubscriptions.map((channel, index) => (
                      <Draggable
                        key={channel.channelId}
                        draggableId={channel.channelId}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="bg-gray-200 rounded-lg md:p-4 p-1 flex items-center md:max-h-48 max-h-12"
                          >
                            <img
                              src={channel.thumbnails.default.url}
                              alt={channel.title}
                              className="md:w-12 md:h-12 w-0 h-0 rounded-full bg-gray-400 mr-4"
                            />
                            <span className="font-semibold overflow-x-auto">
                              {channel.title}
                            </span>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    {hasMore && (
                      <div className="col-span-2 text-center py-4">
                        Loading more...
                      </div>
                    )}
                  </div>
                )}
              </Droppable>
            </div>
            <div className="md:w-1/3 w-full">
              <input
                type="text"
                placeholder="Enter your slot name"
                value={slotName}
                onChange={(e) => setSlotName(e.target.value)}
                className="w-full p-2 mb-4 border rounded"
              />
              <Droppable droppableId="selectedChannels">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="bg-gray-100 p-4 rounded-lg mb-4 min-h-[400px]"
                  >
                    <h3 className="text-center text-gray-500 mb-2">
                      DRAG AND DROP YOUR CHANNELS HERE
                    </h3>
                    {selectedChannels.map((channel, index) => (
                      <Draggable
                        key={channel.channelId}
                        draggableId={channel.channelId}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="bg-white rounded p-2 mb-2 flex justify-between items-center"
                          >
                            <span>{channel.title}</span>
                            <button
                              onClick={() => removeFromSlot(channel.channelId)}
                              className="text-red-500"
                            >
                              Remove
                            </button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <button
                onClick={createSlot}
                className="w-full bg-amber-400 hover:bg-yellow-600 text-neutral-800 font-bold py-2 rounded"
              >
                Create slot!
              </button>
              <p className="text-center mt-2 text-gray-500">
                You have {selectedChannels.length}/5 channels in this slot
              </p>
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default CreateSlot;
