import { useState, useEffect } from 'react';
//import jwt_decode from 'jwt-decode'; // You'll need to install this package: npm install jwt-decode

const useUserInfo = () => {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        try {
  //        const decodedToken = jwt_decode(token);
          const googleAccessToken = localStorage.getItem('google_accessToken');
          
          let profilePicture = null;
          let googleName=null;
          if (googleAccessToken) {
            const response = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
              headers: {
                Authorization: `Bearer ${googleAccessToken}`,
              },
            });
            if (response.ok) {
              const data = await response.json();
              profilePicture = data.picture;
              googleName = data.name;
            }
          }

          setUserInfo({
            googleName,
            profilePicture,
          });
        } catch (error) {
          console.error('Error decoding token or fetching user info:', error);
        }
      }
    };

    fetchUserInfo();
  }, []);

  return userInfo;
};

export default useUserInfo;
