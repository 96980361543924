// components/ProtectedRoute.js
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import axios from "axios";

const ProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      try {
        // Instead of making a separate API call, decode the token and check its expiration
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp > currentTime) {
          // Token is still valid
          setIsAuthenticated(true);
        } else {
          // Token has expired, try to refresh
          await refreshToken();
        }
      } catch (error) {
        handleLogout();
      }
    };

    verifyToken();
  }, []);

  const refreshToken = async () => {
    try {
      const refresh_token = localStorage.getItem("refreshToken");
      const response = await axios.post(`/api/auth/refresh-token`, {
        refresh_token,
      });
      localStorage.setItem("accessToken", response.data.access_token);
      setIsAuthenticated(true);
    } catch (refreshError) {
      handleLogout();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setIsAuthenticated(false);
  };

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/pricing" state={{ from: location }} replace />
  );
};

export default ProtectedRoute;
