import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/landingPage/landingPage.js";
import ContactUs from "./pages/contactUs/contactUs.js";
import MySlot from "./pages/mySlot/mySlot.js";
import CreateSlot from "./pages/createSlot/createSlot.js";
// import PricingPage from "./pages/pricingPage/pricingPage.js";
import PricingPage from "./pages/pricingPage/freePricingPage.js";

import ProtectedRoute from "./components/protectedRoute" 
import AuthCheck from "./components/AuthCheck"
import ProtectedLayout from './components/ProtectedLayout';
import ExtensionPolicy from "./pages/privacyPolicy/extension.js";
import GoogleOAuthPolicy from "./pages/privacyPolicy/googleAuth.js";


import { ChakraProvider } from '@chakra-ui/react'



function App() {
  return (
    <ChakraProvider>    
    <AuthCheck>
    <Routes>
      {/* Public routes */}
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path="/pricing" element={<PricingPage />} />
      <Route exact path="/privacy-policy-extension" element={<ExtensionPolicy />} />
      <Route exact path="/privacy-policy" element={<GoogleOAuthPolicy />} />


      {/* Protected routes */}
      <Route element={<ProtectedRoute />}>
           <Route element={<ProtectedLayout />}>
          <Route exact path="/contactus" element={<ContactUs />} />
          <Route exact path="/create-slot" element={<CreateSlot />} />
          <Route exact path="/my-slot" element={<MySlot />} />
          </Route>
      </Route>
      {/* Catch all route - can be used for a 404 page */}
      <Route path="*" element={<PricingPage />} />
    </Routes>
    </AuthCheck>
    </ChakraProvider>

  );
}

export default App;
