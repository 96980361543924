// components/LogoutButton.js
// components/LogoutButton.js
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

const LogoutButton = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleLogout = async () => {
    try {
      const refresh_token = localStorage.getItem("refreshToken");
      const access_token = localStorage.getItem("accessToken");

      // Send request to blacklist the refresh token
      await axios.post(
        `/api/auth/logout`,
        { refresh_token },
        { headers: { Authorization: `Bearer ${access_token}` } },
      );

      // Clear tokens from local storage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");

      // Close the modal and redirect to landing page
      onClose();
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);

      // Even if the server request fails, we should still clear local storage and redirect
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      onClose();
      navigate("/");
    }
  };

  return (
    <>
      <button
        onClick={onOpen}
        className="w-full text-left py-2 px-4 bg-transparent text-white hover:bg-neutral-600 rounded transition-colors"
      >
        Logout
      </button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-black bg-opacity-60 backdrop-blur-sm" />
        <ModalContent className="bg-neutral-800 text-white rounded-md">
          <ModalHeader className="">Confirm Logout</ModalHeader>
          <ModalCloseButton className="text-white" />
          <ModalBody className="py-4">
            Are you sure you want to log out?
          </ModalBody>

          <ModalFooter className="">
            <button
              className="mr-3 px-4 py-2 bg-neutral-700 text-white rounded hover:bg-neutral-600 transition-colors"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
              onClick={handleLogout}
            >
              Logout
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LogoutButton;
