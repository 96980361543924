import React from 'react';

const SearchBar = ({ searchTerm, onSearchChange ,placeholder_input }) => {
  return (
    <div className="mb-4">
      <input
        type="text"
        placeholder= {placeholder_input}
        value={searchTerm}
        onChange={(e) => onSearchChange(e.target.value)}
        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  );
};

export default SearchBar;
