import React, { useState } from "react";
import {
  FaLinkedin,
  FaYoutube,
  FaTwitter,
  FaEnvelope,
} from "react-icons/fa";
import { useToast } from "@chakra-ui/react";
import AyushGuptaImg from "../../../components/assets/images/AyushGuptaImg.png";
import TiyaJainImg from "../../../components/assets/images/TiyaJainImg.png";
import LandingPageLogo from "../../../components/assets/images/LandingPageLogo.png"

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitPseudo = ()=>{
    console.log("deactivated")
  }

  const handleSubmit = async (e) => {

    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch("/api/send-mail/landing-page", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast({
          title: "Message sent.",
          description: "We've received your message. Thank you for contacting us!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setFormData({ name: "", email: "", message: "" });
      } else {
        toast({
          title: "Failed to send message.",
          description: "There was an error sending your message. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
           position: "top",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "An error occurred.",
        description: "There was a problem with our server. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-neutral-800 text-gray-100 flex flex-col items-center justify-center p-8 min-h-screen">
      <h1 className="text-xl font-bold text-yellow-500 mb-12">Contact us</h1>
      <div className="flex flex-col lg:flex-row flex-wrap justify-center gap-12 w-full max-w-7xl">
        <div className="p-6 flex flex-col space-y-8 max-w-xl w-full">
          {/* FilterOut Contact Card */}
          <div className="flex items-center bg-neutral-700 p-6 rounded-3xl mb-12 space-x-6 md:mr-32  w-auto ">
            <img
              src={LandingPageLogo}
              alt="FilterOut"
              className="lg:w-24 lg:h-24 w-16 h-16"
            />
            <div>
              <h2 className="lg:text-2xl font-semibold">FilterOut</h2>
              <div className="flex lg:space-x-6 space-x-2 mt-4">
                <a
                  href="https://www.linkedin.com/company/filterout/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin className="text-stone-300 lg:text-2xl" />
                </a>
                {/* <a
                  href="https://www.youtube.com/@madmecodes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube className="text-stone-300 lg:text-2xl" />
                </a>
                <a
                  href="https://x.com/madmecodes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter className="text-stone-300 lg:text-2xl" />
                </a> */}
                <a href="mailto:filterout.in@gmail.com">
                  <FaEnvelope className="text-stone-300 lg:text-2xl" />
                </a>
              </div>
            </div>
          </div>
          {/* <div className="flex items-center bg-neutral-700 p-6 rounded-3xl mb-12 space-x-6 md:mr-32  w-auto ">
            <img
              src={AyushGuptaImg}
              alt="Ayush Gupta"
              className="lg:w-24 lg:h-24 w-16 h-16 rounded-full"
            />
            <div>
              <h2 className="lg:text-2xl font-semibold">Ayush Gupta</h2>
              <div className="flex lg:space-x-6 space-x-2 mt-4">
                <a
                  href="https://www.linkedin.com/in/ayush-gupta-dev/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin className="text-stone-300 lg:text-2xl" />
                </a>
                <a
                  href="https://www.youtube.com/@madmecodes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube className="text-stone-300 lg:text-2xl" />
                </a>
                <a
                  href="https://x.com/madmecodes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter className="text-stone-300 lg:text-2xl" />
                </a>
                <a href="mailto:f20231185@pilani.bits-pilani.ac.in">
                  <FaEnvelope className="text-stone-300 lg:text-2xl" />
                </a>
              </div>
            </div>
          </div> */}
          {/* Tiya Jain Contact Card */}
          {/* <div className="flex items-center justify-right bg-neutral-700 p-6 md:ml-32  w-auto rounded-3xl space-x-6">
            <div className="ml-auto">
              <h2 className="lg:text-2xl font-semibold">Tiya Jain</h2>
              <div className="flex lg:space-x-6 space-x-3 mt-4">
                <a
                  href="https://www.linkedin.com/in/tiyajain24/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin className="text-stone-300 lg:text-2xl" />
                </a>
                <a
                  href="https://x.com/TiyaJain24"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter className="text-stone-300 lg:text-2xl" />
                </a>
                <a href="mailto:tiyajain4124@gmail.com">
                  <FaEnvelope className="text-stone-300 lg:text-2xl" />
                </a>
              </div>
            </div>
            <div className="mr-24">
              <img
                src={TiyaJainImg}
                alt="Tiya Jain"
                className="lg:w-24 lg:h-24 h-16 w-16 rounded-full"
              />
            </div>
          </div> */}
        </div>
        {/* Contact Form */}
        <div className="p-8 rounded-3xl w-full max-w-xl">
          {/* <form onSubmit={handleSubmit} className="space-y-6"> */}
          <form onSubmit={handleSubmitPseudo} className="space-y-6">

            <div>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                className="w-full p-4 bg-neutral-600 rounded-xl text-white placeholder-gray-400 text-lg"
                required
              />
            </div>
            <div>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your Email"
                className="w-full p-4 bg-neutral-600 rounded-xl text-white placeholder-gray-400 text-lg"
                required
              />
            </div>
            <div>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={6}
                placeholder="Message"
                className="w-full p-4 bg-neutral-600 rounded-xl text-white placeholder-gray-400 resize-none text-lg"
                required
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full py-4 bg-yellow-500 text-gray-900 font-semibold rounded-xl hover:bg-yellow-600 text-xl"
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;