import React, { useState } from "react";
import GoogleLoginYoutube from "../../components/googleLoginYoutube";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LandingPageLogo from "../../components/assets/images/LandingPageLogo.png";
import YoutubeLogo from "../../components/assets/images/YoutubeLogo.png";
import { IoMdArrowBack } from "react-icons/io";
import { useToast } from "@chakra-ui/react";


const PricingPage = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const [googleAccessToken, setGoogleAccessToken] = useState("");
  const [googleRefreshToken, setGoogleRefreshToken] = useState("");

  const handleGoogleSuccess = (userInfo) => {
    setEmail(userInfo.email);
    setUsername(userInfo.name);
    setGoogleAccessToken(userInfo.google_accessToken);
    setGoogleRefreshToken(userInfo.google_refreshToken);
  };

  const toast = useToast();


  const handleFreeSignup = async () => {
    try {
      const response = await axios.post('/api/payments/free-signup', {
        email,
        username,
        googleAccessToken,
        googleRefreshToken
      });
      
      if (response.data.access_token && response.data.refresh_token) {
        localStorage.setItem("accessToken", response.data.access_token);
        localStorage.setItem("refreshToken", response.data.refresh_token);
      }
      localStorage.setItem("google_accessToken", googleAccessToken);
      localStorage.setItem("google_refreshToken", googleRefreshToken);

      toast({
        title: "Signup successful!",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      navigate("/create-slot");
    } catch (error) {
      if (error.response && error.response.data.error === "Email already exists") {
        toast({
          title: "Email already exists",
          description: "Please sign in instead",
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        navigate("/");
      } else {
        console.error("Signup failed", error);
        toast({
          title: "Signup failed",
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };


  return (
    <div className="flex flex-col lg:flex-row h-screen">
      {/* Left side */}
      <div className="w-full lg:w-1/2 bg-neutral-800 p-8 flex flex-col justify-between">
        <div>
          <div className="flex items-center mb-8">
            <button onClick={() => navigate(-1)} className="text-white mr-4">
              <IoMdArrowBack className="h-6 w-6" />
            </button>
            <div className="flex items-center">
              <img
                src={LandingPageLogo}
                alt="FilterOut"
                className="h-8 w-8 mr-2"
              />
              <h1 className="text-white text-2xl font-bold">FilterOut</h1>
            </div>
          </div>
          <h2 className="text-white text-xl mb-4">Sign up for FilterOut</h2>
          <h3 className="text-white text-4xl font-bold mb-8">Free</h3>
        </div>
        <div>
          <p className="text-gray-400 text-sm mb-12">
            Note: The extension will be activated on the account you sign up
            with Google. Make sure to choose the correct account that is linked
            with your YouTube account!
          </p>
        </div>
      </div>

      {/* Right side */}
      <div className="w-full lg:w-1/2 bg-white p-6 lg:p-12 flex flex-col items-center">
        <div className="w-full max-w-lg">
          <div className="flex flex-col lg:flex-row items-center p-6 mb-12 w-full justify-center">
            <div className="rounded-full p-2 mr-4 mb-4 lg:mb-0">
              <img
                src={YoutubeLogo}
                alt="YouTube"
                className="h-auto -rotate-12 w-32 lg:w-96"
              />
            </div>
            <h2 className="text-xl lg:text-2xl font-bold text-center lg:text-left">
              Sign up with the YouTube account you wish to customize.
            </h2>
          </div>

          <div className="mb-4">
            <GoogleLoginYoutube onSuccess={handleGoogleSuccess} />
          </div>

          <input
            type="email"
            placeholder="Email"
            value={email}
            readOnly
            className="w-full px-4 py-2 my-6 border rounded-full bg-gray-200"
          />
          <input
            type="text"
            placeholder="Name"
            value={username}
            readOnly
            className="w-full px-4 py-2 my-6 border rounded-full bg-gray-200"
          />
          <button
            onClick={handleFreeSignup}
            className="w-full px-4 py-3 my-6 bg-yellow-500 text-black rounded-full font-semibold hover:bg-yellow-400"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;