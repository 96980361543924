// components/GoogleLoginYoutube.js
import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { FaGoogle } from 'react-icons/fa';


const GoogleLoginYoutube = ({ onSuccess }) => {
  const handleSuccess = async (response) => {
    console.log("Login Success. Full response:", response);
    try {
      const userInfoResponse = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        },
      );
      const userInfo = {
        email: userInfoResponse.data.email,
        name: userInfoResponse.data.name,
        google_accessToken: response.access_token,
        google_refreshToken: response.refresh_token,
      };
      if (onSuccess) {
        onSuccess(userInfo);
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const handleError = (error) => {
    console.error("Login Failed:", error);
  };

  const login = useGoogleLogin({
    onSuccess: handleSuccess,
    onError: handleError,
    scope: "email profile https://www.googleapis.com/auth/youtube.readonly",
    access_type: 'offline', // Ensure refresh_token is included
  });

  return (
      <button 
      onClick={() => login()} 
      className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-full flex items-center justify-center bg-white hover:bg-gray-50 transition-colors"
    >
      <FaGoogle className="mr-2" />
      Sign up with Google
    </button>
  );
};

export default GoogleLoginYoutube;
